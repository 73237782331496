import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BotConfiguration } from 'src/app/core/models/bot.configuration.model';
import { DaoService } from '../../core/dao/dao.service';

export interface EditorComponentData {
  monacoEditorOptions: any,
  botConfiguration: BotConfiguration
}

@Component({
  selector: 'app-custom-strategy',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss']
})


export class EditorComponent implements OnInit {
  @Input() data!: any;

  constructor(
    public activeModal: NgbActiveModal,
    private toastr: ToastrService,
    private dao: DaoService) {
      console.log(this.data);
  }

  ngOnInit(): void {

  }


}



<div class="bot-toolbar">
  <div class="header-container w-100 d-block d-sm-none">
    <span>{{botTypeCaption}}</span>
    <div class="input-group">
      <input [(ngModel)]="searchString" type="text" class="form-control form-control-sm" id="search" aria-describedby="search" placeholder="Search Bot">
      <button class="btn btn-outline-secondary" (click)="create()">Create New</button>
      <button class="btn btn-outline-secondary" (click)="refreshData()">Refresh Data</button>
    </div>
  </div>
  <div class="header-container w-100 d-none d-sm-block">
    <div class="input-group ">
      <span class="input-group-text">{{botTypeCaption}}</span>
      <input [(ngModel)]="searchString" type="text" class="form-control form-control-sm" id="search" aria-describedby="search" placeholder="Search Bot">
      <button class="btn btn-outline-primary" (click)="create()">Create New</button>
      <button class="btn btn-outline-secondary" (click)="refreshData()">Refresh Data</button>
      <button class="btn btn-outline-secondary" (click)="toggle()">Expand/Collapse</button>
    </div>
  </div>
</div>

<div class="bot-container">
  <div *ngFor='let bot of botConfigurations | searchFilter:searchString;' class="bot-container-child col-xl-4 col-sm-6 col-xs-12">
    <div class="single-bot-container">
      <div class="container single-bot-header">
        <div class="row">
          <div class="form-group col-9">
            <label for="name">Name</label>
            <input [(ngModel)]="bot.name" type="text" class="form-control form-control-sm" id="name" aria-describedby="name">
          </div>
          <div class="form-group col-3">
            <label for="is-active">Is Active</label>
            <input readonly [value]="bot.isActive" type="text" class="form-control form-control-sm" id="is-active" aria-describedby="is active">
          </div>
        </div>
        <div class="row">
          <div class="form-group col">
            <label for="name">Id</label>
            <input readonly [value]="bot.id" type="text" class="form-control form-control-sm" id="bot-id" aria-describedby="bot-id">
          </div>
        </div>
        <div class="row">
          <div class="btn-group" role="group" aria-label="" style="margin-top: 5px">
            <button class="btn btn-outline-primary btn-sm" (click)="start(bot)">Start</button>
            <button class="btn btn-outline-primary  btn-sm" (click)="stop(bot)">Stop</button>
          </div>
        </div>
      </div>
      <json-editor [options]="getOptions(bot)" [data]="bot.configuration" (change)="onConfigurationChanged($event, bot)"></json-editor>
      <div class="single-bot-toolbar">
        <div class="btn-group btn-group-justified" role="group" aria-label="">
          <button class="btn btn-outline-primary  btn-sm" (click)="getLogs(bot.id)">Logs</button>
          <button class="btn btn-outline-primary  btn-sm" (click)="getOrders(bot.id, showOrdersContent)">Orders</button>
          <button class="btn btn-outline-primary  btn-sm" (click)="getTrades(bot.id, showTradesContent)">Trades</button>
          <button class="btn btn-outline-primary  btn-sm" (click)="openStrategyEditor(bot)" *ngIf="isCustomStrategy(bot)">Edit Strategy</button>
        </div>
        <div class="btn-group btn-group-justified" role="group" aria-label="">
          <button class="btn btn-outline-primary btn-sm" (click)="update(bot)">Update</button>
          <button class="btn btn-outline-primary btn-sm" (click)="clone(bot)">Clone</button>
          <button class="btn btn-outline-primary btn-sm" (click)="delete(bot, deleteBotConfirm)">Delete</button>
        </div>
      </div>
    </div>
  </div>
</div>

<ngx-loading [ngClass]="{'loader': isLoading}" [show]="isLoading"></ngx-loading>

<ng-template #deleteBotConfirm let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Delete Bot</h4>
  </div>
  <div class="modal-body">
    Are you sure you want to delete this bot?
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark btn-sm" (click)="modal.close('Ok')">Ok</button>
    <button type="button" class="btn btn-outline-dark btn-sm" (click)="modal.close('Cancel')">Cancel</button>
  </div>
</ng-template>

<ng-template #showTradesContent let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Trades</h4>
  </div>
  <div class="modal-body">
    <ag-grid-angular style="width: 100%; height: 100%"
                     class="ag-theme-alpine"
                     [columnDefs]="columnDefs"
                     [defaultColDef]="defaultColDef"
                     [rowData]="rowData$"
                     [rowSelection]="'multiple'"
                     [animateRows]="true"
                     (gridReady)="onGridReady($event)"
                     [sideBar]="sideBar"
                     [pivotPanelShow]="pivotPanelShow"
                     (cellClicked)="onCellClicked($event)"></ag-grid-angular>
  </div>
  <div class="modal-footer">
    <span>Profit: {{currentProfit}}</span>        
    <button type="button" class="btn btn-outline-dark btn-sm" (click)="modal.close()">Close</button>
  </div>
</ng-template>

<ng-template #showOrdersContent let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Orders</h4>
  </div>
  <div class="modal-body">
    <ag-grid-angular style="width: 100%; height: 100%"
                     class="ag-theme-alpine"
                     [columnDefs]="columnDefs"
                     [defaultColDef]="defaultColDef"
                     [rowData]="rowData$"
                     [rowSelection]="'multiple'"
                     [animateRows]="true"
                     (gridReady)="onGridReady($event)"
                     [sideBar]="sideBar"
                     [pivotPanelShow]="pivotPanelShow"
                     (cellClicked)="onCellClicked($event)"></ag-grid-angular>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark btn-sm" (click)="modal.close()">Close</button>
  </div>
</ng-template>


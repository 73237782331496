import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';
import { ToastrService } from 'ngx-toastr';
import { DaoService } from '../../../core/dao/dao.service';
import { BotBaseComponent } from '../bot.base.component';

@Component({
  selector: 'app-gridbot',
  templateUrl: '../bot.base.component.html',
  styleUrls: ['../bot.base.component.scss']
})

export class GridbotComponent extends BotBaseComponent implements OnInit {

  constructor(
    modalService: NgbModal,
    toastr: ToastrService,
    dao: DaoService) {
    super(modalService, toastr, dao);
    this.template = "fixed_grid";
    this.botTypeCaption = "Gridbot";
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  override getProfit(botId: any, trades: any[]) {
    let profit = 0;
    let divider = 1000000
    for (var i = 0; i < trades.length; i++) {
      let element = trades[i]
      if (element.TradeType === "SELL")
        profit += (element.Price / divider) * (element.Amount / divider)
      if (element.TradeType === "BUY")
        profit -= (element.Price / divider) * (element.Amount / divider)
    }
    return Math.round(profit * 100) / 100
  }

}



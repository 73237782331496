import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BotConfiguration } from "../models/bot.configuration.model";

@Injectable({
  providedIn: 'root',
})
export class DaoService {
  baseUrl: string;

  constructor(public http: HttpClient,
    @Inject('ENDPOINTS') baseUrl: any
  ) {
    this.baseUrl = baseUrl["AdminConsole.Service"];
  }

  public getTrades(botId: string): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.http.get<any>(this.baseUrl + 'bot/trades?botId=' + botId, { headers });
  }

  public getOrders(botId: string): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.http.get<any>(this.baseUrl + 'bot/orders?botId=' + botId, { headers });
  }

  public getLogs(botId: string): Observable<any> {
    return this.http.get(this.baseUrl + 'bot/logs?botId=' + botId, { responseType: 'blob', reportProgress: true });
  }

  public start(bot: BotConfiguration): Observable<BotConfiguration> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post<BotConfiguration>(this.baseUrl + 'bot/start', JSON.stringify(bot.id), { headers });
  }

  public stop(bot: BotConfiguration): Observable<BotConfiguration> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post<BotConfiguration>(this.baseUrl + 'bot/stop', JSON.stringify(bot.id), { headers });
  }

  public update(bot: BotConfiguration): Observable<BotConfiguration> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    let payload = Object.assign({}, bot);
    payload.configuration = JSON.stringify(bot.configurationToUpdate);
    return this.http.put<BotConfiguration>(this.baseUrl + 'bot/update', payload, { headers });
  }

  public create(template: string): Observable<BotConfiguration> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post<BotConfiguration>(this.baseUrl + 'bot/create', JSON.stringify(template), { headers });
  }

  public clone(bot: BotConfiguration): Observable<BotConfiguration> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
    return this.http.post<BotConfiguration>(this.baseUrl + 'bot/clone', JSON.stringify(bot.id), { headers });
  }

  public delete(bot: BotConfiguration): Observable<any> {
    return this.http.delete(this.baseUrl + 'bot/delete/', { params: { id: bot.id } });
  }

  public getBotConfigurations(template: string): Observable<BotConfiguration[]> {
    return this.http.get<BotConfiguration[]>(this.baseUrl + 'bot/get?template=' + template);
  }
}

<header>
  <nav class="navbar navbar-expand-sm navbar-toggleable-sm navbar-light bg-white border-bottom box-shadow mb-3">
    <div class="container-fluid">
      <a class="navbar-brand" [routerLink]="['/home']">
        <img src="assets/logo.png" alt="" class="logo">
      </a>
      <button class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target=".navbar-collapse"
              aria-label="Toggle navigation"
              [attr.aria-expanded]="isExpanded"
              (click)="toggle()">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="navbar-collapse collapse d-sm-inline-flex flex-sm-row-reverse" *ngIf="authService.isLoggedIn"
           [ngClass]="{ show: isExpanded }">
        <ul class="navbar-nav flex-grow">
          <li class="nav-item"
              [routerLinkActive]="['link-active']"
              [routerLinkActiveOptions]="{ exact: true }">
            <a class="nav-link text-dark animated-text" [routerLink]="['/hummingbot']">Hummingbot</a>
          </li>
          <li class="nav-item" [routerLinkActive]="['link-active']">
            <a class="nav-link text-dark animated-text" [routerLink]="['/mastrategybot']">MaStrategybot</a>
          </li>
          <li class="nav-item" [routerLinkActive]="['link-active']">
            <a class="nav-link text-dark animated-text" [routerLink]="['/gridbot']">Gridbot</a>
          </li>
          <li class="nav-item" [routerLinkActive]="['link-active']">
            <a class="nav-link text-dark animated-text" [routerLink]="['/momentum']">Momentum</a>
          </li>
          <li class="nav-item" [routerLinkActive]="['link-active']">
            <a class="nav-link text-dark animated-text" [routerLink]="['/custom']">Custom</a>
          </li>
          <li class="nav-item">
            <a class="nav-link text-dark " style="cursor:pointer" (click)="authService.SignOut()">Log Out</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';
import { ToastrService } from 'ngx-toastr';
import { DaoService } from '../../../core/dao/dao.service';
import { BotBaseComponent } from '../bot.base.component';

@Component({
  selector: 'app-ma-strategy',
  templateUrl: '../bot.base.component.html',
  styleUrls: ['../bot.base.component.scss']
})

export class MaStrategyComponent extends BotBaseComponent implements OnInit{

  constructor(
    modalService: NgbModal,
    toastr: ToastrService,
    dao: DaoService) {
    super(modalService, toastr, dao);
    this.template = "ma_strategy"
    this.botTypeCaption = "MA Strategy";
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  override getProfit(botId: any, trades: any[]) {
    let profit = 0;
    for (var i = 0; i < trades.length; i++) {
      let element = trades[i]
      profit += element.CloseProfitAbs
    }
    return Math.round(profit * 100) / 100
  }

}



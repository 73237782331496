<div class="container">
  <div class="row flex-center">
    <h4 class="flex-center">Sign In</h4>
    <form class="sign-in-form">
      <div class="form-group">
        <label for="exampleInputEmail1">Email address</label>
        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" #userName required>
      </div>
      <div class="form-group">
        <label for="exampleInputPassword1">Password</label>
        <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Password" #userPassword required>
      </div>
      <div class="btn btn-primary" (click)="signIn(userName.value, userPassword.value)">Log In</div>
    </form>
  </div>
</div>


<ngx-loading [ngClass]="{'loader': isLoading}" [show]="isLoading"></ngx-loading>

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';
import { ToastrService } from 'ngx-toastr';
import { DaoService } from '../../core/dao/dao.service';
import { BotConfiguration } from '../../core/models/bot.configuration.model';
import { AgGridAngular } from 'ag-grid-angular';
import { CellClickedEvent, ColDef, ColGroupDef, GridReadyEvent, SideBarDef } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { AsyncAlertComponent } from 'src/app/core/async/alert/async.alert.component';
import { EditorComponent } from '../editor/editor.component';


@Component({
  selector: 'app-bot-base',
  templateUrl: './bot.base.component.html',
  styleUrls: ['./bot.base.component.scss']
})

export class BotBaseComponent implements OnInit {
  public botConfigurations: BotConfiguration[] = [];
  public isLoading: boolean = true;
  public searchString: string;
  public modalService: NgbModal;
  public template!: string;
  public dao: DaoService;
  public toastr: ToastrService;
  public botTypeCaption: string;
  public currentTrades!: any;
  public currentOrders!: any;
  public currentProfit!: number;
  public columnDefs: ColDef[] = [];
  public defaultColDef: ColDef = {
    sortable: true,
    resizable: true,
    width: 100,
    filter: true,
    enableRowGroup: true,
    enablePivot: true,
    enableValue: true
  };
  public sideBar: SideBarDef | string | string[] | boolean | null = {
    toolPanels: ['columns'],
  };
  public rowGroupPanelShow: 'always' | 'onlyWhenGrouping' | 'never' = 'always';
  public pivotPanelShow: 'always' | 'onlyWhenPivoting' | 'never' = 'always';
  public rowData$!: any[];

  @ViewChild(JsonEditorComponent, { static: false }) editor!: JsonEditorComponent;
  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;
  editorOptions: any = {};

  constructor(
    modalService: NgbModal,
    toastr: ToastrService,
    dao: DaoService) {
    this.modalService = modalService;
    this.searchString = "";
    this.dao = dao;
    this.toastr = toastr;
    this.template = "bot_base";
    this.botTypeCaption = "Bot Base";
  }

  ngOnInit(): void {
    this.refreshData();
  }

  start(bot: BotConfiguration) {
    this.isLoading = true;
    this.dao.start(bot).subscribe(result => {
      this.refreshData();
      this.isLoading = false;
      this.toastr.success("Bot Started");
    }, error => {
      console.error(error);
      this.isLoading = false;
    });
  }

  stop(bot: BotConfiguration) {
    this.isLoading = true;
    this.dao.stop(bot).subscribe(result => {
      this.refreshData();
      this.isLoading = false;
      this.toastr.success("Bot Stopped");

    }, error => {
      console.error(error);
      this.isLoading = false;
    });
  }

  update(bot: BotConfiguration) {
    this.isLoading = true;
    if (!bot.configurationToUpdate) bot.configurationToUpdate = bot.configuration;
    this.dao.update(bot).subscribe(result => {
      this.isLoading = false;
      this.toastr.success("Bot Updated");

    }, error => {
      console.error(error);
      this.isLoading = false;
    });
  }

  create() {
    this.isLoading = true;
    this.dao.create(this.template).subscribe(result => {
      result.configuration = JSON.parse(result.configuration);
      this.botConfigurations.push(result);
      this.isLoading = false;
      this.toastr.success("Bot Created");

    }, error => {
      console.error(error);
      this.isLoading = false;
    });
  }

  clone(bot: BotConfiguration) {
    this.isLoading = true;
    this.dao.clone(bot).subscribe(result => {
      result.configuration = JSON.parse(result.configuration);
      this.botConfigurations.push(result);
      this.isLoading = false;
      this.toastr.success("Bot Cloned");

    }, error => {
      console.error(error);
      this.isLoading = false;
    });
  }

  delete(bot: BotConfiguration, content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      if (result.toLowerCase() == "ok") {
        this.isLoading = true;
        this.dao.delete(bot).subscribe(result => {
          this.isLoading = false;
          this.toastr.success("Bot Deleted");
          this.refreshData();
        }, error => {
          console.error(error);
          this.isLoading = false;
        });
      }
    }, (reason) => {

    });
  }

  refreshData() {
    this.isLoading = true;
    this.dao.getBotConfigurations(this.template).subscribe(result => {
      for (var i = 0; i < result.length; i++) {
        let item = result[i];
        item.configuration = JSON.parse(item.configuration);
      }
      this.botConfigurations = result;
      this.isLoading = false;
      this.toastr.success("Data Reloaded");

    }, error => {
      this.isLoading = false;
      console.error(error)
    });
  }

  getOptions = (bot: BotConfiguration) => {
    let options = new JsonEditorOptions();
    options.modes = ['code', 'text', 'tree', 'view'];
    this.editorOptions[bot.id] = options;
    return options;
  }

  onConfigurationChanged($event: any, bot: any) {
    bot.configurationToUpdate = $event;
  }

  // Example load data from sever
  onGridReady(params: GridReadyEvent) {

  }

  // Example of consuming Grid Event
  onCellClicked(e: CellClickedEvent): void {
    console.log('cellClicked', e);
  }

  // Example using Grid's API
  clearSelection(): void {
    this.agGrid.api.deselectAll();
  }

  open(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
    }, (reason) => {
    });
  }

  getTrades(botId: string, content: any) {
    this.dao.getTrades(botId)
      .subscribe((trades) => {
        if (trades.length) {
          this.currentProfit = this.getProfit(botId, trades);
          this.updateGridData(trades)
        }
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', fullscreen: true })
          .result
          .then((result) => {
            this.currentTrades = [];
          }, (reason) => { });
      });
  }

  getOrders(botId: string, content: any) {
    this.dao.getOrders(botId)
      .subscribe((orders) => {
        if (orders.length) {
          this.updateGridData(orders)
        }
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', fullscreen: true })
          .result
          .then((result) => {
            this.currentOrders = [];
          }, (reason) => { });
      });
  }

  getLogs(botId: string) {
    this.dao.getLogs(botId)
      .subscribe((data) => {
        const downloadedFile = new Blob([data], { type: data.type });
        const a = document.createElement('a');
        a.setAttribute('style', 'display:none;');
        document.body.appendChild(a);
        a.download = "logfile_" + botId + ".log";
        a.href = URL.createObjectURL(downloadedFile);
        a.target = '_blank';
        a.click();
        document.body.removeChild(a);

      }, error => {
        this.toastr.warning("No logs yet");
      });
  }

  getProfit(botId: any, trades: any[]) {
    return 0;
  }

  updateGridData(data: any[]) {
    let keys = Object.keys(data[0]);
    this.columnDefs = [];
    for (let key in keys) {
      let value = keys[key]
      if (/timestamp/.test(value.toLowerCase()) || /date/.test(value.toLowerCase())) {
        this.columnDefs.push({
          cellRenderer: (d: any) => {
            return d.value ? (new Date(d.value)).toUTCString() : '';
          },
          field: keys[key]
        })
      } else {
        this.columnDefs.push({
          field: keys[key]
        })
      }
    }
    this.rowData$ = data;
  }

  toggle() {
    let elements = document.querySelectorAll("td.jsoneditor-tree button");
    for (let e in elements) {
      let element = elements[e] as HTMLElement;
      if (element.click)
        element.click();
    }
  }

  openStrategyEditor(configuration: BotConfiguration){
    let data = { botConfiguration: configuration, monacoEditorOptions: {theme: 'vs-dark', language: 'python'}};
    let modalRef = this.modalService.open(EditorComponent, {
      fullscreen: true
    })
    modalRef.componentInstance.data = data;
  }

  isCustomStrategy(configuration: BotConfiguration){
    return configuration.template === "custom_strategy";
  }
}


export const environment = {
  production: true,
  endpoints: {
    "AdminConsole.Service": "https://finafrica.net:8443/"
  },
  firebase: {
    apiKey: "AIzaSyCsBgZ1LgChvvPGspvfYJIFVNqzLF3R6Ns",
    authDomain: "adminconsole-432f3.firebaseapp.com",
    projectId: "adminconsole-432f3",
    storageBucket: "adminconsole-432f3.appspot.com",
    messagingSenderId: "970231867850",
    appId: "1:970231867850:web:01c01241d0c344140a4733",
    measurementId: "G-1W294M7KLF"
  }
};

import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { AuthService } from "../../core/auth/auth.service";
@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {

  public isLoading: boolean = false;

  constructor(
    public authService: AuthService,
    public router: Router
  ) { }
  ngOnInit() {
    if (this.authService.isLoggedIn) {
      this.router.navigate(["home"]);
    }
  }

  signIn(userName: string, userPassword: string) {
    this.isLoading = true;
    this.authService.SignIn(userName, userPassword)
      .then(() => {
        this.isLoading = false;
        this.router.navigate(['home']);
      })
      .catch(() => {
        this.isLoading = false;
      });
  }
}

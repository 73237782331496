import { environment } from "src/environments/environment";
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppComponent } from './app.component';
import { NavMenuComponent } from './components/nav-menu/nav-menu.component';
import { HummingbotComponent } from './components/bot/hummingbot/hummingbot.component';
import { GridbotComponent } from './components/bot/gridbot/gridbot.component';
import { SearchFilterPipe } from '../common/pipes/SearchPipe';
import { ngxLoadingAnimationTypes, NgxLoadingModule } from 'ngx-loading';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AuthService } from "./core/auth/auth.service";
import { AuthGuard } from "./core/auth/auth.guard";
import { SignInComponent } from "./components/sign-in/sign-in.component";
import { HomeComponent } from "./components/home/home.component";
import { TokenInterceptor } from "./core/interceptors/auth.interceptor";
import { ToastrModule } from "ngx-toastr";
import { ErrorInterceptor } from "./core/interceptors/error.interceptor";
import { MaStrategyComponent } from "./components/bot/ma-strategy/ma-strategy.component";
import { BotBaseComponent } from "./components/bot/bot.base.component";
import { AgGridModule } from "ag-grid-angular";
import { LiquidityMiningComponent } from "./components/bot/liquidity-mining/liquidity-mining.component";
import { MomentumStrategyComponent } from "./components/bot/momentum-strategy/momentum-strategy.component";
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { CustomStrategyComponent } from "./components/bot/custom-strategy/custom-strategy.component";
import { EditorComponent } from "./components/editor/editor.component";


@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HummingbotComponent,
    MaStrategyComponent,
    BotBaseComponent,
    GridbotComponent,
    LiquidityMiningComponent,
    MomentumStrategyComponent,
    CustomStrategyComponent,
    EditorComponent,
    SearchFilterPipe,
    SignInComponent
  ],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    CommonModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    RouterModule.forRoot([
      { path: '', redirectTo: '/sign-in', pathMatch: 'full' },
      { path: 'sign-in', component: SignInComponent },
      { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
      { path: 'hummingbot', component: HummingbotComponent, canActivate: [AuthGuard] },
      { path: 'mastrategybot', component: MaStrategyComponent, canActivate: [AuthGuard] },
      { path: 'gridbot', component: GridbotComponent, canActivate: [AuthGuard] },
      { path: 'momentum', component: MomentumStrategyComponent, canActivate: [AuthGuard] },
      { path: 'custom', component: CustomStrategyComponent, canActivate: [AuthGuard] },
    ]),
    NgJsonEditorModule,
    NgbModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.pulse
    }), 
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      progressBar: true
    }),
    AgGridModule,
    MonacoEditorModule.forRoot()
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true
  }, {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorInterceptor,
    multi: true
  }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
